.block.block-detail {
    padding: 10rem 0;
    background-color: $blue-2;

    .row {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        [class^="column"] {
            float: none;
        }
    }

    .column-6 {
        @include set-grid-prop(width, 6, 6.5);
        height: 57rem;
        position: relative;

        svg {
            position: absolute;
            width: 82.6rem;
            height: 57rem;
            top: 0;
        }
    }

    &.type-1 {

        .column-6 {
            svg {
                right: 0;
            }
        }

        .column-4 {
            @include set-grid-prop(margin-left, 2, 1);
        }
    }

    &.type-2 {

        .column-6 {
            order: 2;
            @include set-grid-prop(margin-left, 2, 2);

            svg {
                left: 0;
            }
        }

        .column-4 {
            order: 1;
            margin-left: 0;
        }
    }

    .button-rect {
        margin-top: 4rem;
    }

    @media screen and (max-width: $mobile) {
        padding: 5rem 0;

        .r-1 {
            flex-direction: column;

            .column-6 {
                order: 1 !important;
                height: auto;

                svg {
                    position: relative;
                    width: 100%;
                    height: auto;
                }
            }

            .column-4 {
                order: 2 !important;
                margin-top: 4.4rem;
            }
        }
    }

    + .block-detail {
        padding-top: 0;
    }

}