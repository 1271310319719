/**
BUTTON RECT
 */
.button-rect {

    .button__inner {
        display: inline-block;
        text-decoration: none;
        @include averta-std-bold();
        letter-spacing: 0.03em;
        font-size: 1.4rem;
        line-height: 1.4;
        padding: 1.9rem 3rem 1.6rem 3rem;
        text-transform: uppercase;
        transition: color 0.6s $easeOutQuart;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: transform 0.3s $easeOutQuart, background-color 0.3s $easeOutQuart;
            background-color: transparent;
        }

        html.desktop & {
            &:hover {
                &:before {
                    transform: skewX(-25deg);
                }
            }
        }
    }

    &.transparent-cyan {

        .button__inner {
            color: $cyan;

            &:before {
                border: 1px solid $cyan;
            }

            html.desktop & {
                &:hover {
                    color: white;

                    &:before {
                        background-color: $cyan;
                    }
                }
            }
        }
    }

    &.transparent-green {

        .button__inner {
            color: $green;

            &:before {
                border: 1px solid $green;
            }

            html.desktop & {
                &:hover {
                    color: white;

                    &:before {
                        background-color: $green;
                    }
                }
            }
        }
    }

    &.white {
        .button__inner {
            color: white;

            &:before {
                border: 1px solid white;
            }

            html.desktop & {
                &:hover {
                    color: $blue-2;

                    &:before {
                        background-color: white;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        .button__inner {
            padding: 1.7rem 3rem 1.6rem 3rem;
        }
    }

}

.button-line {
    display: inline-block;

    .button__inner {
        @include averta-std-regular(1.4rem, 1.4rem, 0.03em);
        text-transform: uppercase;
        transition: color 0.6s $easeOutQuart;
        display: flex;
        align-items: center;

        svg {
            width: 1.7rem;
            height: 1.7rem;
            transition: transform 0.6s $easeOutQuart;
            margin-left: 1rem;
        }

        html.desktop & {
            &:hover {
                color: white;

                svg {
                    transform: translate(4px, 0);
                }
            }
        }
    }
}