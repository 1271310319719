.block.block-solutions  {
    padding: 10rem 0;

    .r-1 {

        .column-6 {
            @include set-grid-prop(margin-left, 3, 3);
            text-align: center;
        }

        .up-title {
            text-align: center;
            @include averta-std-regular(1.4rem, 1.4rem, 0.03em);
            text-transform: uppercase;
        }

        h2 {
            margin-top: 1rem;
        }
    }

    .r-2 {
        margin-top: 7rem;
        height: 42rem;

        &:before {
            content: "";
            opacity: 0.7;
            z-index: -1;
            width: 32rem;
            height: 73rem;
            background: rgb(69,218,241);
            background: linear-gradient(90deg, rgba(69,218,241,1) 0%, rgba(19,200,182,1) 100%);
            position: absolute;
            bottom: -10rem;
            left: 0;
            transform: translate(-50%, 0) skewX(-25deg);
        }

        .navigation {
            right: 0;
            top: 7rem;
            transform: translate(50%, 0);
        }

        .swiper-slide {
            position: relative;
            height: 42rem;
            @include set-grid-prop(width, 3, 4);

            a {
                display: block;
                width: 100%;
                height: 100%;
            }

            &:after {
                content: "";
                background-color: $blue-2;
                @include full-absolute;
                pointer-events: none;
                z-index: 10;
                opacity: 0.5;
                transition: opacity 0.6s $easeOutQuart;
            }

            .thumbnail {
                width: 100%;
                height: 100%;
                overflow: hidden;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                &:after {
                    @include full-absolute;
                    position: none;
                    content: "";
                    background: rgb(15,20,42);
                    background: linear-gradient(180deg, rgba(15,20,42,0) 0%, rgba(15,20,42,1) 100%);
                    z-index: 2;
                }
            }

            .title {
                position: absolute;
                @include averta-std-bold(2.4rem, 3.4rem, 0.05em);
                color: white;
                z-index: 10;
                bottom: 5rem;
                left: 4rem;
                width: calc(100% - 8rem);

                &:before {
                    opacity: 0;
                    position: absolute;
                    background: rgb(69,218,241);
                    background: linear-gradient(90deg, rgba(69,218,241,1) 0%, rgba(19,200,182,1) 100%);
                    width: 2.8rem;
                    height: 4.4rem;
                    z-index: -1;
                    left: -1rem;
                    top: -0.6rem;
                    transform: translate(0, 4px) skewX(-25deg);
                    content: "";
                    transition: transform 0.6s $easeOutQuart, opacity 0.6s $easeOutQuart;
                }
            }

            html.desktop & {
                &:hover {

                    &:after {
                        opacity: 0;
                    }

                    .title {

                        &:before {
                            transform: translate(0, 0) skewX(-25deg);
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 6rem 0;

        .swiper {
            @media screen and (max-width: $mobile) {
                overflow: visible !important;
            }
        }

        .r-2 {

            &:before {
                height: 40rem;
                width: 17rem;
                bottom: auto;
                top: -4rem;
                left: -5rem;
            }

            .swiper-slide {
                height: 33rem;

                .title {
                    font-size: 1.6rem;
                    line-height: 2.6rem;
                    left: 3rem;
                    bottom: 3rem;
                }

            }
        }
    }

}