.block.block-sponsored {
    padding: 6rem 0;

    a {
        display: block;

        html.desktop & {

            &:hover {
                .button-rect {
                    .button__inner {
                        color: white;

                        &:before {
                            background-color: $green;
                            transform: skewX(-25deg);
                        }
                    }
                }
            }
        }
    }

    .column-12 {
        height: 35rem;

        .sponsored-banner {
            opacity: 0.85;
            background-color: $green;
            position: absolute;
            right: 0;
            top: 0;
            height: 4rem;
            padding: 0 2.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            @include averta-std-regular(1.4rem, 1.4rem, 0.03em);
            color: $blue-2;
            text-transform: uppercase;
            z-index: 10;
        }

        .background {
            position: relative;
            height: 100%;
            width: 100%;
            overflow: hidden;

            &:after {
                content: "" ;
                background: rgb(13,17,37);
                background: linear-gradient(-90deg, rgba(13,17,37,0) 0%, rgba(13,17,37,1) 100%);
                @include full-absolute;
                //opacity: 0.3;
                z-index: 1;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .over {
            padding: 5rem;
            @include full-absolute;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            z-index: 2;

            .title {
                @include averta-std-bold(3.2rem, 4.6rem, -0.05em);
                color: white;
            }

            .description {
                color: white;
                margin-top: 2rem;
                max-width: 46rem;
            }

            .button-rect {
                margin-top: 3rem;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 5rem 0;

        .column-12 {
            height: auto;

            .sponsored-banner {
                left: 0;
                right: auto;
                height: 2.4rem;
                font-size: 1.2rem;
                line-height: 1.2rem;
                padding-top: 0.2rem;
            }

            .background {
                position: absolute;
                top: 0;
                left: 0;
            }

            .over {
                position: relative;
                top: auto;
                left: auto;
                padding: 7rem 3rem 3rem 3rem;
                min-height: 34rem;

                .title {
                    font-size: 2rem;
                    line-height: 3rem;
                }

                .description {
                    margin-top: 2rem;
                }
            }
        }
    }
}