/*********************
BASE
*********************/
@import "base/normalize";
@import "base/reset";
@import "base/ress";

/*********************
HELPERS
*********************/
@import "helpers/functions";
@import "helpers/easing";
@import "helpers/helpers";
@import "helpers/basicLightbox.min";
@import "helpers/_choices.css";
@import "../../node_modules/locomotive-scroll/dist/locomotive-scroll.css";

/*********************
COMMON
*********************/
@import "common/grid";
@import "common/fonts";
@import "common/colors";
@import "common/buttons";
@import "common/animations";
@import "common/form";
@import "common/content";

/*********************
HEADER
*********************/
@import "components/header";

/*********************
FOOTER
*********************/
@import "components/footer";

/*********************
PAGE
*********************/
@import "layouts/page";

/*********************
BLOCKS
*********************/
@import "blocks/block-text";
@import "blocks/block-quote-content";
@import "blocks/block-list";
@import "blocks/block-head-banner";
@import "blocks/block-introduction";
@import "blocks/block-detail";
@import "blocks/block-text-logo";
@import "blocks/block-portrait";
@import "blocks/block-testimonial";
@import "blocks/block-solutions";
@import "blocks/block-key-numbers";
@import "blocks/block-icons";
@import "blocks/block-logos";
@import "blocks/block-video";
@import "blocks/block-image";
@import "blocks/block-ad";
@import "blocks/block-contact";
@import "blocks/block-news";
@import "blocks/block-navigation";
@import "blocks/block-values";
@import "blocks/block-text-image";

/*********************
PAGES
*********************/
@import "pages/home";
@import "pages/news";
@import "pages/single";
@import "pages/contact";
@import "pages/common";
@import "pages/error";