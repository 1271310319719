.block-testimonial {
    padding: 20rem 0;
    background-color: $blue-1;

    &:after {
        opacity: 0.7;
        right: -10rem;
        content: "";
        bottom: 0;
        position: absolute;
        background: rgb(144,47,221);
        background: linear-gradient(25deg, rgba(144,47,221,1) 0%, rgba(0,141,255,1) 100%);
        width: 15.6rem;
        height: 36rem;
        z-index: 10;
        transform: skewX(-25deg);
    }

    .navigation {
        top: 7.2rem;
        left: 0;
    }

    .images {
        position: absolute;
        width: 100%;
        height: 76rem;
        overflow: hidden;
        z-index: 1;

        img {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
            opacity: 0;

            &:first-child {
                opacity: 1;
            }
        }
    }

    .grid,
    .row {
        position: relative;
        height: 76rem;
        z-index: 20;
    }

    .column-5 {
        position: relative;
        height: 100%;
        @include set-grid-prop(margin-left, 7, 7);
    }

    .swiper-pagination {
        margin-top: 5rem;
        padding: 0 4rem;
    }

    .testimonial-item {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: none;
        flex-direction: column;
        justify-content: center;
        @include autoAlpha(0);

        &:first-child {
            display: flex;
            @include autoAlpha(1);
        }

        .testimonial-item__up-title {
            text-align: left;
            @include averta-std-regular(1.4rem, 1.4rem, 0.03em);
            text-transform: uppercase;
        }

        .testimonial-item__content {
            margin-top: 2rem;
            @include averta-std-regular(2.8rem, 4.4rem, -0.05em);
            color: white;
        }

        .testimonial-item__metas {
            margin-top: 4rem;
            @include averta-std-regular(1.6rem, 2.5rem, 0.03em);
            color: white;

            &:before {
                content: "";
                opacity: 0.7;
                position: absolute;
                background: rgb(144,47,221);
                background: linear-gradient(25deg, rgba(144,47,221,1) 0%, rgba(0,141,255,1) 100%);
                width: 2.4rem;
                height: 5rem;
                z-index: -1;
                left: -1.4rem;
                top: -0.2rem;
                transform: skewX(-25deg);
            }
        }

        .button-rect {
            margin-top: 4rem;
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 5rem 0;

        &:after {
            right: -3rem;
            width: 6rem;
            height: 14rem;
            bottom: 0;
        }

        .grid,
        .row {
            height: auto;
        }

        .navigation {
            display: none;
        }

        .images {
            height: 23rem;
            overflow: hidden;

            &:after {
                content: "";
                @include full-absolute;
                background: rgb(15,20,42);
                background: linear-gradient(180deg, rgba(15,20,42,0) 0%, rgba(15,20,42,1) 100%);
                z-index: 2;
            }
        }

        .testimonial-item {
            padding-top: 18rem;

            .testimonial-item__up-title {
                font-size: 1.2rem;
                line-height: 1;
            }

            .testimonial-item__content {
                font-size: 1.8rem;
                line-height: 3rem;
            }

            .testimonial-item__metas {
                font-size: 1.4rem;
                line-height: 2.2rem;
            }
        }
    }
}