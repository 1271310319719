.block.block-key-numbers {
    padding: 10rem 0;

    .grid {
        border: 1px solid rgba(255, 255, 255, 0.1);
        padding: 9rem 0;
        background-color: $blue-1;
    }

    .navigation {
        right: 0;
        top: 50%;
        transform: translate(50%, -50%);
    }

    .r-1 {
        text-align: center;
    }

    .r-2 {
        margin-top: 10rem;

        .swiper-slide {
            position: relative;
            @include set-grid-prop(width, 3, 4);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .number {
                position: relative;
                @include averta-std-bold(8rem, 9rem);
                color: white;

                &:before {
                    content : "";
                    position: absolute;
                    background: rgb(144,47,221);
                    background: linear-gradient(25deg, rgba(144,47,221,1) 0%, rgba(0,141,255,1) 100%);
                    width: 5rem;
                    height: 6rem;
                    z-index: -1;
                    left: -1rem;
                    top: 0.4rem;
                    transform: skewX(-25deg);
                }
            }

            .legend {
                position: relative;
                margin: 6rem auto 0 auto;
                max-width: 17rem;
                text-align: center;
            }
        }

        .swiper-pagination {
            text-align: center !important;
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 6rem 0;

        .grid {
            padding: 4rem 0;
        }

        .r-2 {
            margin-top: 5rem;

            .swiper-slide {

                .number {
                    font-size: 4rem;
                    line-height: 5rem;

                    &:before {
                        width: 3rem;
                        height: 4rem;
                    }
                }

                .legend {
                    margin-top: 2rem;
                }
            }
        }
    }
}