@mixin fontSize($font-size, $line-height:null, $letter-spacing:null){
    font-size:$font-size;
    @if $line-height == null {line-height:$font-size;}
    @else {line-height:$line-height;}
    @if $letter-spacing == null {letter-spacing:0;}
    @else {letter-spacing:$letter-spacing;}
}

@font-face {
    font-family: 'AvertaStd-Bold';
    src: url('../fonts/AvertaStd-Bold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/AvertaStd-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/AvertaStd-Bold.woff') format('woff'), /* Modern Browsers */
    url('../fonts/AvertaStd-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/AvertaStd-Bold.svg#AvertaStd-Bold') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'AvertaStd-Light';
    src: url('../fonts/AvertaStd-Light.eot'); /* IE9 Compat Modes */
    src: url('../fonts/AvertaStd-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/AvertaStd-Light.woff') format('woff'), /* Modern Browsers */
    url('../fonts/AvertaStd-Light.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/AvertaStd-Light.svg#AvertaStd-Light') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'AvertaStd-Regular';
    src: url('../fonts/AvertaStd-Regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/AvertaStd-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/AvertaStd-Regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/AvertaStd-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/AvertaStd-Regular.svg#AvertaStd-Regular') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'AvertaStd-RegularItalic';
    src: url('../fonts/AvertaStd-RegularItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/AvertaStd-RegularItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/AvertaStd-RegularItalic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/AvertaStd-RegularItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/AvertaStd-RegularItalic.svg#AvertaStd-RegularItalic') format('svg'); /* Legacy iOS */
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


@mixin averta-std-bold($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'AvertaStd-Bold', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin averta-std-light($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'AvertaStd-Light', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin averta-std-regular($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'AvertaStd-Regular', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin averta-std-regular-italic($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'AvertaStd-RegularItalic', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}
