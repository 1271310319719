#footer {
    position: relative;
    height: 20rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 10;
    background-color: $blue-2;

    .logo {
        margin-left: 6rem;
        width: 11.9rem;
        height: 8.7rem;

        img {
            width: 11.9rem;
            height: 8.7rem;
        }
    }

    .language {
        position: relative;
        cursor: pointer;
        margin-left: 11.2rem;

        .language__current {
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .icon {
                opacity: 0.6;

                svg {
                    width: 16px;
                    height: 16px;
                }
            }

            .current {
                padding-top: 0.2rem;
                margin-left: 1rem;

                span {
                    @include averta-std-regular(1.6rem, 1, 0.036em);
                }
            }

            .arrow {
                width: 8px;
                height: 8px;
                margin-left: 1rem;
                padding-top: 0.2rem;
            }
        }

        .language__list {
            @include autoAlpha(0);
            padding: 2rem 2rem 5rem 2rem;
            z-index: 1;
            background-color: $blue-1;
            position: absolute;
            bottom: -1rem;
            width: calc(100% + 4rem);
            left: -2rem;
            box-shadow: 0 0 19px rgba(10, 12, 27, 0.8);
            transition: opacity 0.6s $easeOutQuart;

            ul {

                li {
                    padding: 1rem 0;
                    @include averta-std-regular(1.4rem, 1, 0);

                    a {
                        transition: color 0.6s $easeOutQuart;

                        html.desktop & {
                            &:hover {
                                color: white;
                            }
                        }
                    }

                    &:first-child {
                        margin-top: 0;
                    }

                    &.head {
                        padding-bottom: 1rem;
                        margin-bottom: 1rem;
                        text-transform: uppercase;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    }
                }
            }

            &.active {
                @include autoAlpha(1);
            }
        }

        html.desktop & {
            &:hover {
                .language__list {
                    @include autoAlpha(1);
                }
            }
        }

    }

    .footer__right {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .footer__menu {

        #menu {

            ul {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                li {
                    margin-left: 6.6rem;
                    @include averta-std-regular(1.4rem, 1, 0.036em);
                    transition: color 0.6s $easeOutQuart;

                    html.desktop & {
                        &:hover {
                            color: white;
                        }
                    }
                }
            }
        }
    }

    .credits {
        margin-left: 6.6rem;
        @include averta-std-regular(1.4rem, 1, 0.036em);

        a {
            @include averta-std-bold();
            transition: color 0.6s $easeOutQuart;

            html.desktop & {
                &:hover {
                    color: white;
                }
            }
        }
    }

    .socials {
        min-width: 19rem;
        margin-left: 16rem;
        position: relative;

        &:before {
            opacity: 0.65;
            content: "";
            background: rgb(144,47,221);
            background: linear-gradient(40deg, rgba(144,47,221,1) 0%, rgba(0,141,255,1) 100%);
            position: absolute;
            z-index: -1;
            pointer-events: none;
            top: 50%;
            right: -8rem;
            width: 29rem;
            height: 20rem;
            transform: translate(0, -50%) skewX(-25deg);
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: center;

            li {
                padding: 0 3.8rem;
                border-left: 1px solid rgba(255, 255, 255, 0.1);

                &:first-child {
                    border-left: none;
                }

                svg {
                    width: 1.8rem;
                    height: 1.8rem;
                }

                &.twitter {
                    padding-top: 0.2rem;
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        height: auto;
        padding: 5rem 0 0 0;
        flex-direction: column;
        justify-content: center;

        .logo {
            margin-left: 0;
        }

        .language {
            margin-top: 4rem;
            margin-left: 0;
        }

        .footer__right {
            margin-top: 4rem;
            margin-left: 0;
            flex-direction: column;
            justify-content: center;


            .footer__menu {
                #menu {
                    ul {
                        flex-direction: column;

                        li {
                            margin-left: 0;
                            margin-top: 3rem;

                            &:first-child {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }

            .credits {
                margin-top: 3rem;
                margin-left: 0;
            }

            .socials {
                margin-top: 13rem;
                padding-bottom: 5rem;
                margin-left: 0;

                ul {

                    li {
                        padding: 0 7rem;

                        svg {
                            width: 2.3rem;
                            height: 2.3rem;
                        }
                    }
                }

                &:before {
                    right: auto;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(-5deg);
                    width: 110vw;
                }
            }
        }
    }
}