.block-text {
    padding: 10rem 0;

    .column-8 {
        padding: 5rem 0;
        background-color: $blue-1;
        @include set-grid-prop(margin-left, 2, 2);
    }

    &.center {
        text-align: center;

        .column-8 {
            @include set-grid-prop(width, 7, 6);
            @include set-grid-prop(margin-left, 2, 3);
            //color: white;
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 5rem 0;
    }
}