#header {
    position: fixed;
    width: 100%;
    z-index: 1000;
    background: rgb(10,16,39);
    background: linear-gradient(0deg, rgba(10,16,39,0) 0%, rgba(10,16,39,1) 100%);

    .header__top {
        position: relative;
        margin: 0 auto;
        height: 16.6rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: calc(100% - 12rem);
        z-index: 2;


        .logo {
            width: 11.9rem;
            height: 8.7rem;
            z-index: 10;

            img {
                width: 11.9rem;
                height: 8.7rem;
            }
        }

        #menu {
            margin-left: 9.4rem;
            height: 100%;

            .main {
                height: 100%;
                display: flex;
                align-items: stretch;
                justify-content: flex-start;

                > .menu-item {
                    flex: 0 0 auto;
                    height: 100%;

                    .menu-link-main {
                        position: relative;
                        height: 100%;
                        z-index: 10;
                    }

                    .nav-item {
                        cursor: pointer;
                        padding: 0 2rem;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        transition: color 0.6s $easeOutQuart;
                        font-size: 1.6rem;
                    }

                    &.current-menu-ancestor,
                    &.current-menu-parent,
                    &.current-menu-item {
                        .nav-item {
                            color: rgba(255, 255, 255, 1);
                        }
                    }

                    html.desktop & {
                        &:hover {
                            .nav-item {
                                color: rgba(255, 255, 255, 1);
                            }

                            .submenu {
                                //display: flex;
                                pointer-events: all;
                                left: 0;
                                transition: none;
                                z-index: 1;
                                /*left: 0;
                                opacity: 1;
                                transform: translate(0, 0);*/

                                &:before {
                                    opacity: 1;
                                }

                                &:after {
                                    opacity: 0.6;
                                }

                                .submenu__inner {
                                    transform: translate(0, 0);
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .header__top-right {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: 4;

        .sign-up {
            cursor: pointer;
            font-size: 1.6rem;
            line-height: 1;

            html.desktop & {
                &:hover {
                    .sign-up__list {
                        @include autoAlpha(1);
                    }
                }
            }

            .sign-up__label {
                pointer-events: none;
                position: relative;
                padding: 1.9rem 4.4rem 1.8rem 4.4rem;
                z-index: 2;
            }

            .sign-up__list {
                @include autoAlpha(0);
                width: 100%;
                position: absolute;
                top: 0;
                background-color: $blue-2;
                z-index: 1;
                padding-top: 5.3rem;
                transition: opacity 0.6s $easeOutQuart;
                box-shadow: 0 0 19px rgba(10, 12, 27, 0.8);

                ul {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0.8rem 0;
                    border-top: 1px solid rgba(216, 216, 216, 0.1);

                    li {
                        padding: 0.8rem 0;
                        margin-top: 0.4rem;
                        transition: color 0.6s $easeOutQuart;

                        html.desktop & {
                            &:hover {
                                color: white;
                            }
                        }

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .button-rect {
            margin-left: 4.5rem;

            .button__inner {
                padding-left: 3rem;
                padding-right: 3rem;
            }
        }
    }

    .header__bottom {
        position: absolute;
        left: 0;
        width: 100%;
        top: 16.6rem;
        z-index: 1;
    }

    .submenu {
        z-index: 0;
        height: 24rem;
        position: fixed;
        left: 200vw;
        top: 16.6rem;
        //background-color: $blue-2;
        width: 100%;
        //display: none;
        display: flex;
        pointer-events: none;
        //opacity: 0;
        //transform: translate(0, -10px);
        //transition: transform 0.6s $easeOutQuart, opacity 0.6s $easeOutQuart;
        transition: left 0s 0.6s;

        &:before {
            pointer-events: none;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(0, -100%);
            z-index: 1;
            background-color: $blue-2;
            height: 20rem;
            opacity: 0;
            width: 100%;
            transition: opacity 0.6s $easeOutQuart;
        }

        &:after {
            content: "";
            opacity: 0;
            position: absolute;
            pointer-events: none;
            top: 0;
            left: 0;
            z-index: 1;
            background-color: $blue-2;
            height: 100vh;
            width: 100%;
            transition: opacity 0.6s $easeOutQuart;
        }

        .submenu__inner {
            background-color: $blue-2;
            height: 100%;
            width: 100%;
            opacity: 0;
            z-index: 2;
            border-top: 1px solid rgba(255, 255, 255, 0.12);
            border-bottom: 1px solid rgba(255, 255, 255, 0.12);
            transform: translate(0, -10px);
            transition: transform 0.6s $easeOutQuart, opacity 0.6s $easeOutQuart;
        }

        /*&.visible {
            display: flex;
        }*/

        /*html.desktop & {
            &:hover {
                display: flex !important;
            }
        }*/

        &:not(.has-depth) {

            .submenu__list {
                margin: 0 auto;
                width: calc(100% - 12rem);
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                padding: 2rem 0;
                z-index: 4;

                .child-no-depth {
                    margin-left: 2rem;
                    flex: 1 1 auto;
                    width: 100%;
                    height: 20rem;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }

            .child-has-depth__container {
                display: none;
            }
        }

        &.has-depth {
            height: 47.5rem;
            //height: 46rem;
            //display: flex;
            align-items: stretch;
            justify-content: flex-start;

            .submenu__inner {
                display: flex;
                align-items: stretch;
                justify-content: flex-start;
            }

            .submenu__list {
                z-index: 4;
                flex: 0 0 auto;
                margin: 0 auto;
                height: 100%;
                display: flex;
                align-items: stretch;
                justify-content: flex-start;
                flex-direction: column;
                position: relative;
                width: 40rem;

                &:before {
                    content: "";
                    background-color: white;
                    width: 1px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 40rem;
                    opacity: 0.12;
                }

                .child-has-depth {
                    width: 40rem;
                    border-top: 1px solid rgba(255, 255, 255, 0.12);
                    padding-left: 6rem;

                    &:first-child {
                        border-top: none;
                    }

                    &.current {
                        .has-depth__title {
                            color: white;
                        }
                    }

                    .has-depth__title {
                        cursor: pointer;
                        @include averta-std-bold();
                        font-size: 2.4rem;
                        line-height: 1.4;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding: 4rem 4rem 4rem 0;
                        transition: color 0.6s $easeOutQuart;

                        svg {
                            margin-left: auto;
                            width: 10px;
                            height: 10px;
                        }

                        html.desktop & {
                            &:hover {
                                color: white;
                            }
                        }
                    }
                }
            }

            .child-has-depth__container {
                position: relative;
                flex: 0 0 auto;
                width: calc(100% - 40rem);
                height: 100%;
                overflow: hidden;
                z-index: 4;
                //padding: 2rem 3rem;

                .child-has-depth__wrapper {
                    padding: 2rem 3rem;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    /*display: flex;
                    align-items: stretch;
                    justify-content: flex-start;
                    flex-wrap: wrap;*/
                    transition: opacity 0.6s $easeOutQuart;
                    @include autoAlpha(0);

                    .scrollbar-track {
                        background-color: $blue-2;

                        .scrollbar-thumb {
                            background-color: white;
                        }
                    }

                    .scroll-content {
                        display: flex;
                        align-items: stretch;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        width: 100%;
                        height: auto;
                    }

                    &.current {
                        @include autoAlpha(1);
                    }

                    /*&:first-child {
                        display: flex;
                    }*/

                    li {
                        height: 20rem;
                        width: calc((100% - 4rem) / 3);
                        margin-left: 2rem;
                        margin-top: 2rem;

                        &:nth-child(3n + 1) {
                            margin-left: 0;
                        }

                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3) {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .menu-thumb-link {

            a {
                display: block;
                width: 100%;
                height: 100%;

                &:after {
                    content: "";
                    @include full-absolute;
                    pointer-events: none;
                    background-color: $blue-1;
                    opacity: 0.5;
                    z-index: 3;
                    transition: opacity 0.6s $easeOutQuart;
                }

                html.desktop & {
                    &:hover {

                        &:after {
                            opacity: 0;
                        }

                        .title {

                            span {

                                &:before {
                                    opacity: 1;
                                    transform: translate(0, 0) skewX(-25deg);
                                }

                            }
                        }
                    }
                }
            }

            .thumbnail {
                position: relative;
                width: 100%;
                height: 100%;
                background: rgb(144,47,221);
                background: linear-gradient(25deg, rgba(144,47,221,1) 0%, rgba(0,141,255,1) 100%);

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                &:after {
                    content: "";
                    pointer-events: none;
                    opacity: 0.5;
                    background: rgb(15,20,42);
                    background: linear-gradient(180deg, rgba(15,20,42,0) 0%, rgba(15,20,42,1) 100%);
                    @include full-absolute;
                    z-index: 1;
                }
            }

            .title {
                @include full-absolute;
                z-index: 2;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                padding: 3.3rem;
                @include averta-std-bold(2rem, 1.5em);
                color: white;

                span {
                    position: relative;
                    display: inline-block;
                    transform: translate3d(0, 0, 0.1px);

                    &:before {
                        opacity: 0;
                        position: absolute;
                        content: "";
                        background: rgb(144,47,221);
                        background: linear-gradient(25deg, rgba(144,47,221,1) 0%, rgba(0,141,255,1) 100%);
                        width: 2.8rem;
                        height: 4rem;
                        z-index: -1;
                        left: -1rem;
                        top: -0.6rem;
                        transform: translate(-5px, 10px) skewX(-25deg);
                        transition: transform 0.6s $easeOutQuart, opacity 0.6s $easeOutQuart;
                    }
                }
            }
        }

        &:first-child {
            .menu-thumb-link a {
                .title {
                    span {
                        &:before {
                            background: rgb(69,218,241);
                            background: linear-gradient(90deg, rgba(69,218,241,1) 0%, rgba(19,200,182,1) 100%);
                        }
                    }
                }
            }
        }
    }
}

#header-mobile {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    padding: 3rem;
    align-items: center;

    &:after {
        pointer-events: none;
        @include full-absolute;
        content: "";
        z-index: 0;
        background: rgb(15,20,42);
        background: linear-gradient(0deg, rgba(15,20,42,0) 0%, rgba(15,20,42,1) 100%);
    }

    .logo {
        position: relative;
        width: 16.9rem;
        height: 4.5rem;
        z-index: 2;

        a {
            display: block;
        }

        img {
            width: 16.9rem;
            height: 4.5rem;
        }
    }

    .button-open-menu {
        margin-left: auto;
        position: relative;
        z-index: 2;
        width: 2.6rem;
        height: 2rem;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;

        .line {
            width: 80%;
            height: 1px;
            background-color: white;

            &.l-1 {
                margin-left: 20%;
            }

            &.l-2 {
                margin-left: 10%;
            }
        }
    }

}

#menu-mobile {
    @include autoAlpha(0);
    @include full-fixed;
    background-color: $blue-2;
    z-index: 100000;
    padding-top: 7.6rem;

    &.more-space {

        .menu-mobile__main-list {
            border-bottom: none;

            .button-rect {
                margin-top: 3rem;
            }

            ul {
                li {
                    margin-top: 1.5rem;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .menu-mobile__depth-0 {
        position: relative;
        height: 100%;
        overflow-y: scroll;
    }

    .menu-mobile__top {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: $blue-2;
        height: 7.6rem;
        padding: 0 3rem 1rem 3rem;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        z-index: 2;

        .back {
            @include autoAlpha(0);
            padding-bottom: 0.2em;

            svg {
                width: 1.8rem;
                height: 1.8rem;
            }
        }

        .close {
            margin-left: auto;

            svg {
                width: 2.2rem;
                height: 2.2rem;
            }
        }
    }

    .menu-mobile__main-list {
        padding: 0 3rem 3rem 3rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        ul {

            li {
                padding: 1rem 0;
                @include averta-std-bold(1.8rem, 2.8rem, 0.04);
                color: white;

                &.current-menu-ancestor,
                &.current-menu-parent,
                &.current-menu-item {
                    text-decoration: underline;
                    text-underline-offset: 0.4rem;
                }

                &.menu-item-has-children {
                    background: transparent url('../images/common/arrow-small-right.png') no-repeat right center;
                    background-size: 4px 8px;
                }
            }
        }

        .button-rect {
            margin-top: 2rem;
        }
    }

    .menu-mobile__slave-list {
        margin-top: 2rem;
        padding: 0 3rem 3rem 3rem;

        ul {

            li {
                padding: 1rem 0;
                @include averta-std-bold(1.8rem, 2.8rem, 0.04);
                color: white;
            }
        }
    }

    .menu-mobile__depth-1 {
        transform: translate(100%, 0);
        background-color: $blue-2;
        top: 7.6rem;
        left: 0;
        position: absolute;
        height: calc(100% - 7.6rem);
        padding: 0 3rem;
        width: 100%;
        overflow-y: scroll;
        z-index: 10;

        .submenu-item {
            margin-top: 2rem;

            .parent-title {
                display: none;
                @include averta-std-bold(1.8rem, 2.8rem, 0.04em);
                color: white;
                margin-bottom: 3rem;
            }

            &:not(.has-depth) {

                &:first-child {
                    .parent-title {
                        display: block;
                    }
                }
            }

            &.has-depth {
                margin-top: 4rem;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                padding-bottom: 4rem;

                &:first-child {
                    margin-top: 2rem;
                }

                .parent-title {
                    display: block;
                }

                ul {
                    li {
                        margin-top: 2rem;
                    }
                }
            }

            a {
                display: flex;
                align-items: center;
                @include averta-std-bold(1.4rem, 2.4rem, -0.025em);
                color: white;
            }

            .thumbnail {
                flex: 0 0 auto;
                width: 12rem;
                height: 7.8rem;
                overflow-y: hidden;

                &:after {
                    opacity: 0.5;
                    content: "";
                    @include full-absolute;
                    pointer-events: none;
                    background: rgb(15,20,42);
                    background: linear-gradient(180deg, rgba(15,20,42,0) 0%, rgba(15,20,42,1) 100%);
                    z-index: 1;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .title {
                padding: 0 0 0 3rem;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    #header {
        display: none;
    }

    #header-mobile {
        display: flex;
    }
}