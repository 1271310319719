.block.block-portrait {
    padding: 10rem 0;

    .row {
        display: flex;
        align-items: center;
        margin-top: 20rem;

        &:first-child {
            margin-top: 0;
        }

        [class^='column'] {
            float: none;
        }

        &:nth-child(odd) {

            .content {

            }

            .portrait {
                @include set-grid-prop(margin-left, 3, 2);
            }
        }

        &:nth-child(even) {

            .content {
                order: 2;
                @include set-grid-prop(margin-left, 3, 2);
            }

            .portrait {
                order: 1;

                .shape {
                    left: 0;
                    transform: translate(-50%, -50%) skewX(-25deg);
                }
            }
        }

        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(7),
        &:nth-child(10) {
            .portrait {
                .shape {
                    display: block;
                }
            }
        }
    }

    .title {
        @include averta-std-regular(2.4rem, 3.4rem, 0.05em);
        color: white;
    }

    .text-content {
        margin-top: 4rem;
    }

    .portrait {
        position: relative;

        img {
            width: 100%;
            height: auto;
            z-index: 1;
        }

        .shape {
            display: none;
            opacity: 0.7;
            z-index: -1;
            width: 32rem;
            height: 130%;
            background: rgb(69,218,241);
            background: linear-gradient(90deg, rgba(69,218,241,1) 0%, rgba(19,200,182,1) 100%);
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(50%, -50%) skewX(-25deg);
        }

        .button-linkedin {
            right: 0;
            top: 7rem;
            position: absolute;
            width: 7rem;
            height: 7rem;
            z-index: 2;

            a {
                opacity: 0.85;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background: rgb(144,47,221);
                background: linear-gradient(25deg, rgba(144,47,221,1) 0%, rgba(0,141,255,1) 100%);

                &:after {
                    @include full-absolute;
                    opacity: 0;
                    content: "";
                    background: rgb(0,141,255);
                    background: linear-gradient(25deg, rgba(0,141,255,1) 0%, rgba(144,47,221,1) 100%);
                    transition: opacity 0.6s $easeOutQuart;
                    z-index: 1;
                }

                html.desktop & {
                    &:hover {
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }

            svg {
                position: relative;
                width: 2rem;
                height: 2rem;
                z-index: 2;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 5rem 0;

        .row {
            flex-direction: column;
            margin-top: 10rem;

            &:nth-child(even),
            &:nth-child(odd) {

                .content {
                    order: 2;
                }

                .portrait {
                    order: 1;
                }
            }

            &:nth-child(even) {

                .portrait {

                    .shape {
                        transform: translate(-70%, -50%) skewX(-25deg);
                    }
                }
            }
        }

        .portrait {

            .button-linkedin {
                top: 5rem;
                width: 5rem;
                height: 5rem;

                svg {
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }

            .shape {
                transform: translate(90%, -50%) skewX(-25deg);
            }
        }

        .content {
            margin-top: 5rem;

            .title {
                font-size: 1.8rem;
                line-height: 2.8rem;
            }
        }
    }
}