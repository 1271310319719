.block.block-video  {
    padding: 10rem 0;

    .r-1 {
        position: relative;
        cursor: pointer;

        html.desktop & {
            &:hover {
                .play {
                    transform: translate(-50%, -50%) scale(1.1);
                }
            }
        }

        .video-container {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            height: 0;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .thumbnail {
            position: relative;

            img {
                width: 100%;
                height: auto;
            }

            &:after {
                opacity: 0.4;
                content: "";
                @include full-absolute;
                background-color: $blue-2;
                z-index: 1;
            }
        }

        .play {
            @include center-xy;
            width: 15rem;
            height: 15rem;
            border-radius: 50%;
            border: 1px solid #21E8D8;
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.6s $easeOutQuart;

            svg {
                width: 3rem;
                height: 3.4rem;
                stroke: #21E8D8;
            }
        }
    }

    .column-10 {
        @include set-grid-prop(margin-left, 1, 1);
        margin-top: 4rem;
        text-align: center;
    }

    @media screen and (max-width: $mobile) {
        padding: 5rem 0;

        .r-1 {

            .play {
                width: 5.5rem;
                height: 5.5rem;

                svg {
                    width: 1.4rem;
                    height: auto;
                }
            }
        }

        .column-10 {
            margin-top: 2rem;
        }
    }
}