.block-introduction {
    position: relative;

    .row {
        padding: 10rem 0;
    }

    .column-8 {
        z-index: 2;
        background-color: $blue-1;
        padding: 5rem 0;
        @include set-grid-prop(margin-left, 2, 2);
    }

    .up-title {
        position: relative;
        text-align: center;
        @include averta-std-regular(1.4rem, 1.4rem, 0.03em);
        text-transform: uppercase;

        &:before,
        &:after {
            content: "";
            opacity: 0.7;
            position: absolute;
            background: rgb(144,47,221);
            background: linear-gradient(25deg, rgba(144,47,221,1) 0%, rgba(0,141,255,1) 100%);
            width: 2.4rem;
            height: 4rem;
            z-index: -1;
            top: 50%;
            transform: skewX(-25deg);
        }

        &:before {
            left: calc(50% - 2px);
            transform: translate(-100%, -50%) skewX(-25deg);
        }

        &:after {
            left: calc(50% + 2px);
            transform: translate(0, -50%) skewX(-25deg);
        }

    }

    .content {
        margin-top: 3rem;
        text-align: center;
        @include averta-std-regular(2.8rem, 4.4rem, -0.05em);
        color: white;

        .content__inner {
            position: relative;
            z-index: 2;
        }
    }

    .illustration {
        position: absolute;
        z-index: 1;

        img {
            width: 100%;
            height: auto;
        }

        &.illustration-1 {
            top: 0;
            left: 0;
            transform: translate(-50%, 30%);
        }

        &.illustration-2 {
            bottom: 0;
            right: 0;
            transform: translate(50%, -30%);
        }
    }

    @media screen and (max-width: $mobile) {

        .row {
            padding: 5rem 0;
        }

        .illustration {
            &.illustration-1 {
                display: none;
            }

            &.illustration-2 {
                opacity: 0.3;
                transform: translate(50%, 20%);
            }
        }

        .up-title {
            font-size: 1.2rem;
        }

        .column-8 {
            background-color: transparent;
        }

        .content {
            font-size: 1.8rem;
            line-height: 3rem;
            letter-spacing: -0.03em;

            .icon {
                width: 2rem;
                height: 3rem;

                &.s-2 {
                    width: 2rem;
                    height: 3rem;
                }
            }
        }
    }
}