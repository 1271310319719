html {
    font-size: calc(1em * .625);
    //font-size: 16px; // baseFontSize
    //font-size: clamp(100%, 1rem + 2vw, 16px);

    &.has-scroll-smooth {
        overflow: hidden;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &:not(.is-mobile) {
        overflow: hidden !important;
    }

    @media (min-width: 1024px) {
        font-size: .67568vw;

        @media (min-width: 1440px) {
            font-size: 10px;
        }
    }
}

.otgs-development-site-front-end {
    display: none;
}

body {
    position: relative;
    background-color: $blue-1;
    @include averta-std-regular();
    font-size: 1.8rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.6);
    min-height: 100%;
}

.wrapper {
    position: relative;
    overflow: hidden;
}

.overflow-hidden {
    overflow: hidden;
}

svg,
img {
    display: block;
}

/**
BG LINES
 */
.background-lines {
    @include full-absolute;
    pointer-events: none;

    .grid,
    .row {
        height: 100%;
        width: 100%;
    }
}

.bg-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.09);
    z-index: 1;

    &.bg-1 {
        @include set-grid-prop(left, 1, 1.5);
    }

    &.bg-2 {
       left: 50%;
    }

    &.bg-3 {
        left: auto;
        @include set-grid-prop(right, 1, 1.5);
    }
}

/**
BLOCK
 */
.block {
    position: relative;
    z-index: 2;
}

/**
NAVIGATION
 */
.navigation {
    position: absolute;
    z-index: 10;
    height: 7rem;
    width: 14.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(144,47,221);
    background: linear-gradient(25deg, rgba(144,47,221,1) 0%, rgba(0,141,255,1) 100%);

    .arrow {
        cursor: pointer;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        transition: opacity 0.6s $easeOutQuart;

        &.swiper-button-disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4rem;
            width: 100%;
            border-left: 1px solid rgba(255, 255, 255, 0.18);
        }

        svg {
            width: 2rem;
            height: 2rem;
            fill: white;
            transition: transform 0.6s $easeOutQuart;
        }

        &.left {
            svg {
                transform: rotate(-180deg);
            }
        }

        &:first-child {
            .icon {
                border-left: none;
            }
        }

        html.desktop & {
            &:hover {

                &.left {
                    svg {
                        transform: translate(-4px, 0) rotate(-180deg);
                    }
                }

                svg {
                    transform: translate(4px, 0);
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        display: none;
    }
}

/**
PAGINATION
 */
.swiper-pagination {
    display: none;
    margin-top: 3rem;
    position: relative !important;
    left: auto !important;
    bottom: auto !important;
    text-align: left !important;

    span {
        width: 4rem;
        height: 0.1rem;
        background-color: #D8D8D8;
        opacity: 0.15;
        margin-left: 2rem;
        border-radius: 0;

        &:first-child {
            margin-left: 0;
        }

        &.swiper-pagination-bullet-active {
            opacity: 1;
        }
    }

    @media screen and (max-width: $mobile) {
        display: block;
    }
}

/**
BREADCRUMBS
 */
.section-breadcrumbs {
    padding: 3rem 0;
    font-size: 1.4rem;
    line-height: 1;

    .active {
        color: white;
    }

    a {
        transition: color 0.6s $easeOutQuart;

        html.desktop & {
            &:hover {
                color: white;
            }
        }
    }

    li::after {
        display: inline-block;
        content: "" !important;
        background: transparent url('../images/common/arrow-small-right.png') no-repeat center center;
        background-size: 4px 8px;
        width: 4px;
        height: 8px;
        margin: 0 1rem;
    }
}

/**
LOADER
 */
.loader {
    @include autoAlpha(0);
    display: flex;
    align-items: center;
    justify-content: center;

    .bullet {
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        background-color: $cyan;
        margin-left: 0.5rem;
        //transition: transform 0.6s $easeOutQuart, opacity 0.6s $easeOutQuart;
        animation: 1s linear 1s infinite alternate loading;

        &.b-2 {
            animation-delay: 1.1s;
        }

        &.b-3 {
            animation-delay: 1.2s;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}

@keyframes loading {
    from { /*transform: scale(1);*/ opacity: 1; }
    50%  { /*transform: scale(0.8);*/ opacity: 0; }
    to   { /*transform: scale(1);*/ opacity: 1; }
}

/**
FLIP
 */
#flip {
    @include full-fixed;
    background-color: $blue-1;
    align-items: center;
    justify-content: center;
    padding: 4rem;
    z-index: 10000000000000;
    color: white;
    text-align: center;
    display: none;

    html.tablet & {
        @media screen and (orientation: landscape) {
            display: flex;
            font-size: 3rem;
        }
    }

    @media screen and (max-width: $mobile) and (orientation: landscape) {
        display: flex;
    }
}

/**
COOKIES
 */
#cookie-law-info-bar {
    /*width: 45rem !important;
    height: auto;
    left: 5rem !important;
    bottom: 5rem !important;*/
    background-color: $blue-2 !important;
    color: rgba(255, 255, 255, 0.6) !important;
    box-shadow: 0 0 19px rgba(10, 12, 27, 0.8) !important;

    /*.cli-bar-container {
        flex-direction: column;
        align-items: flex-start;
    }*/

    .cli-style-v2 .cli-bar-message {
        width: 100%;
        line-height: 1.4;
    }

    /*.cli-bar-btn_container {
        margin-left: 0;
        margin-top: 2rem;
    }*/

    .cli-style-v2 .cli-bar-btn_container .cli-plugin-button {
        border: 1px solid white !important;
        background-color: $blue-2 !important;
        color: white !important;
        transition: background-color 0.6s $easeOutQuart;

        &#wt-cli-accept-all-btn {
            border-color: $cyan !important;
            color: $cyan !important;
        }

        &:hover {
            background-color: white !important;
            color: $blue-2 !important;
        }
    }

    @media screen and (max-width: $mobile) {
        left: 0 !important;
        bottom: 0!important;
        width: 100% !important;
    }
}

.cli-tab-footer .wt-cli-privacy-accept-btn {
    background-color: $blue-2 !important;
}

/**
TRANSITION
 */
#transition {
    @include full-fixed;
    z-index: 1000000;

    .panel {
        position: absolute;
        width: 100vw;
        height: 100%;
        left: calc(-50vw + 2px);
        top: 0;
        background-color: $blue-2;
        transform: scaleX(1) skewX(-25deg);

        &.right {
            left: 50%;
        }
    }
}

@media screen and (max-width: $mobile) {

    body {
        font-size: 1.6rem;
    }

    .bg-line {

        &.bg-1 {
            left: 4rem
        }

        &.bg-3 {
            right: 4rem
        }
    }

    .section-breadcrumbs {
        display: none;
    }
}