.block.block-values {
    //background-color: $blue-2;

    .row {
        padding: 18rem 0;
    }

    .illustration {
        position: absolute;
        z-index: 1;

        .img-responsive {
            overflow: hidden;
        }

        img {
            width: 100%;
            height: auto;
        }

        &.illustration-1 {
            bottom: 0;
            left: 0;
            transform: translate(-50%, 0);
        }

        &.illustration-2 {
            top: 0;
            right: 0;
            transform: translate(50%, 0);
        }
    }

    .column-6 {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        float: none;
        @include set-grid-prop(margin-left, 3, 3);

        .icon {
            width: 7rem;
            height: 7rem;

            svg {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }

            .icon-img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        .title {
            text-align: center;
            margin-top: 3rem;
            @include averta-std-bold(8rem, 9rem);
            color: white
        }

        .content {
            text-align: center;
            margin-top: 5rem;
        }
    }

    @media screen and (max-width: $mobile) {
        .row {
            padding: 5rem 0;
        }

        &:nth-child(even) {
            .illustration-1 {
                display: none;
            }
        }

        &:nth-child(odd) {
            .illustration-2 {
                display: none;
            }
        }

        .illustration {
            opacity: 0.3;
            top: 0;

            .img-responsive {
                height: 32rem;
            }
        }

        .up-title {
            font-size: 1.2rem;
        }

        .column-6 {
            background-color: transparent;

            .icon {
                width: 5.5rem;
                height: 5.5rem;
            }

            .title {
                font-size: 4rem;
                line-height: 5rem;
            }

            .content {
                margin-top: 3rem;
            }
        }
    }
}