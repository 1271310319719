#contact {

    .section-head {
        height: 66rem;

        .background {
            position: absolute;
            height: 100%;
            width: 100%;
            overflow: hidden;

            &:after {
                content: "" ;
                background: rgb(13,17,37);
                background: linear-gradient(330deg, rgba(13,17,37,0) 0%, rgba(13,17,37,1) 100%);
                @include full-absolute;
                //opacity: 0.3;
                z-index: 5;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .grid {
            z-index: 10;
            height: 100%;
            padding-top: 16rem;

            .r-1 {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 100%;
            }

            .subtitle {
                color: white;
                margin-top: 5rem;
            }
        }
    }

    .section-content {
        position: relative;
        z-index: 2;

        .column-3 {
            padding: 10rem 0;

            .address-item {
                margin-top: 10rem;

                &:first-child {
                    margin-top: 0;
                }

                .address-item__title {
                    position: relative;
                    @include averta-std-bold(2.4rem, 3.4rem);
                    color: white;

                    &:before {
                        position: absolute;
                        content: "";
                        background: rgb(144,47,221);
                        background: linear-gradient(25deg, rgba(144,47,221,1) 0%, rgba(0,141,255,1) 100%);
                        width: 3.2rem;
                        height: 4rem;
                        z-index: -1;
                        left: -1rem;
                        top: -1rem;
                        transform: translate(-5px, 10px) skewX(-25deg);
                        transition: transform 0.6s $easeOutQuart, opacity 0.6s $easeOutQuart;
                    }
                }

                .address-item__content {
                    margin-top: 5rem;
                }
            }
        }

        .column-7 {
            background-color: $blue-2;
            @include set-grid-prop(margin-left, 3, 2);
            margin-top: -8rem;
            padding: 10rem;

            .loader {
                @include full-absolute;
                z-index: 10;
                background-color: rgba(13, 17, 37, 0.8);
            }

            .success-message {
                background-color: $blue-2;
                @include autoAlpha(0);
                @include full-absolute;
                z-index: 10000000;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 0 4rem;
            }

            form {
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                flex-wrap: wrap;
            }

            .input {
                margin-top: 4rem;
                width: calc(50% - 1.5rem);
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);

                &.error {
                    border-bottom-color: red;
                }

                &.large {
                    width: 100%;
                }

                &:nth-child(1),
                &:nth-child(2) {
                    margin-top: 0;
                }

                .input__legend {
                    @include averta-std-regular(1.4rem, 1.4rem, 0.03rem);
                    opacity: 0.6;
                    text-transform: uppercase;
                    margin-bottom: 2rem;
                }

                select {
                    background: transparent url('../images/common/arrow-small-down.png') no-repeat 100% 8px;
                    background-size: 8px 4px;

                    option {
                        background-color: white !important;
                        color: $blue-1 !important;
                    }
                }

                textarea,
                select,
                input[type="text"] {
                    @include averta-std-regular(1.8rem, 1.2);
                    color: white;
                    padding-bottom: 2rem;
                    width: 100%;
                }

                textarea {
                    height: 10rem;
                    resize: none;
                }
            }

            .file {
                position: relative;
                border-bottom: none;
                cursor: pointer;

                .input__ghost {
                    display: flex;
                    align-items: center;

                    span {
                        margin-left: 1rem;

                        &.icon {
                            margin-top: -0.4rem;
                        }

                        &.legend {
                            @include averta-std-regular(1.4rem, 1, 0.03em);
                            color: white;
                            text-transform: uppercase;
                        }

                        &.accept {
                            @include averta-std-regular(1.4rem, 1, 0.03em);
                        }

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }

                input {
                    opacity: 0.0001;
                    @include full-absolute;
                    z-index: 10;
                }

                .file-name {
                    margin-top: 1.5rem;
                    opacity: 0.6;
                    color: white;
                    font-size: 1.2rem;
                    line-height: 1;
                }
            }

            .end {
                display: flex;
                align-items: center;
                border-bottom: none;

                &.error {
                    color: red;
                }

                .checkbox {
                    cursor: pointer;
                    padding-right: 5rem;
                    display: flex;
                    align-items: center;

                    input {
                        position: absolute;
                        opacity: 0.001;
                    }

                    label {
                        position: relative;
                        padding-left: 4rem;
                        font-size: 1.4rem;

                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0.5rem;
                            width: 2rem;
                            height: 2rem;
                            border: 1px solid $cyan;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            left: 0.5rem;
                            top: 1rem;
                            width: 1rem;
                            height: 1rem;
                            background-color: $cyan;
                            opacity: 0;
                            transition: opacity 0.6s $easeOutQuart;
                        }
                    }

                    input:checked + label {
                        &:after {
                            opacity: 1;
                        }
                    }
                }

                .submit {
                    cursor: pointer;
                    margin-left: auto;
                }
            }

            .form-message {
                margin-top: 4rem;
                font-size: 1.4rem;
                color: red;

                &.success {
                    color: $cyan;
                }
            }

            .form-metas,
            .form-legals {
                margin-top: 6rem;
                font-size: 1.4rem;
            }
        }
    }

    @media screen and (max-width: $mobile) {

        .section-content {

            .grid {
                width: 100%;
            }

            .column-3 {
                padding: 6rem 4rem;

                .address-item {
                    margin-top: 6rem;

                    .address-item__title {
                        font-size: 1.8rem;
                        line-height: 1;

                        &:before {
                            width: 2.4rem;
                            height: 3rem;
                        }
                    }

                    .address-item__content {
                        p {
                            margin-top: 2rem;
                        }
                    }
                }
            }

            .column-7 {
                margin-top: 0;
                padding: 6rem 4rem;
                width: calc(100% + 8rem);
                margin-left: -4rem;
                flex-direction: column;

                .loader {
                    position: fixed;
                }

                .input {
                    width: 100%;
                    margin-top: 4rem !important;

                    &:first-child {
                        margin-top: 0 !important;
                    }

                    select,
                    textarea,
                    input[type="text"] {
                        font-size: 1.6rem;
                    }
                }

                .file {

                    .input__ghost {

                        span {

                            &.accept,
                            &.legend {
                                font-size: 1.2rem;
                            }
                        }
                    }
                }

                .end {
                    flex-direction: column;

                    .checkbox {
                        padding-right: 0;
                    }

                    .submit {
                        margin-top: 4rem;
                        margin-left: 0;
                        width: 100%;
                        text-align: center;
                    }
                }

                .form-legals {
                    font-size: 1.2rem;
                    line-height: 2.4rem;
                }

                .form-metas {
                    margin-top: 4rem;
                }

                .button {
                    .button__inner {
                        width: 100%;
                    }
                }
            }
        }
    }

}