.block.block-logos {
    background-color: $blue-2;
    padding: 10rem 0;

    .r-1 {
        text-align: center;
    }

    .r-2 {
        margin-top: 7rem;

        .swiper {
            //width: 25rem;
            overflow: visible;
        }

        .swiper-slide {
            width: 25rem;
            height: 19rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            &:before {
                position: absolute;
                left: 50%;
                top: 50%;
                content: "";
                border: 1px solid white;
                opacity: 0.1;
                width: 100%;
                height: 100%;
                transform: translate(-50%, -50%) skewX(350deg);
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
            }

            img {
                object-fit: contain;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 5rem 0;
    }
}