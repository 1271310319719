.block.block-icons {
    padding: 10rem 0;
    background-color: $blue-2;

    .r-1 {

        .column-4  {
            @include set-grid-prop(margin-left, 1, 1);
        }
    }

    .r-2 {
        margin-top: 7rem;
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;

        [class^="column"] {
            float: none;
            flex: 0 0 auto;
        }

        .icon-item {
            margin-top: 3rem;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            padding-top: 3rem;
            @include set-grid-prop(margin-left, 3, 2);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: white;

            &:nth-child(1),
            &:nth-child(2) {
                margin-top: 0;
            }

            &:nth-child(2n + 1) {
                @include set-grid-prop(margin-left, 1, 1);
            }

            .icon-item__icon {
                flex: 0 0 auto;
                width: 6rem;

                img {
                    max-width: 100%;
                }
            }

            .icon-item__title {
                margin-left: 5rem;
                padding-right: 2rem;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 5rem 0;

        .r-2 {
            margin-top: 4rem;

            .icon-item {
                margin-top: 3rem !important;

                .icon-item__title {
                    margin-left: 2rem;
                }
            }
        }
    }

}