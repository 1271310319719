.block.block-contact {
    padding: 6rem 0;

    .column-12 {
        height: 60rem;

        .background {
            position: relative;
            height: 100%;
            width: 100%;
            overflow: hidden;

            &:after {
                content: "" ;
                background: rgb(13,17,37);
                background: linear-gradient(330deg, rgba(13,17,37,0) 0%, rgba(13,17,37,1) 100%);
                @include full-absolute;
                opacity: 0.5;
                z-index: 1;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .over {
            padding: 19rem 5rem 5rem 5rem;
            @include full-absolute;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            z-index: 2;

            .title {
                @include averta-std-bold(3.2rem, 4.6rem, -0.05em);
                color: white;
            }

            .description {
                margin-top: 3rem;
                max-width: 46rem;
            }

            .button-rect {
                margin-top: 5rem;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 5rem 0;

        .column-12 {
            height: auto;

            .background {
                position: absolute;
                top: 0;
                left: 0;
            }

            .over {
                position: relative;
                top: auto;
                left: auto;
                padding: 7rem 3rem 3rem 3rem;
                min-height: 34rem;

                .title {
                    font-size: 2rem;
                    line-height: 3rem;
                }

                .description {
                    margin-top: 2rem;
                }
            }
        }
    }
}