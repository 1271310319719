#news {

    .page__inner {
        padding: 20rem 0 10rem 0;
    }

    .section-sponsored {

        .block.block-sponsored {
            padding: 0;
        }
    }

    .loader {
        position: absolute;
        height: 5.4rem;
        width: 100%;
        @include autoAlpha(1);
    }

    .section-title {
        position: relative;
        margin-top: 10rem;
        z-index: 10;

        &:first-child {
            margin-top: 0;
        }

        .r-1 {

            .column-10 {
                @include set-grid-prop(margin-left, 1, 1);
                text-align: center;
            }

            .subtitle {
                margin-top: 3rem;
            }
        }

        .r-2 {
            margin-top: 10rem;

            .choices__inner {
                background-color: $purple;
                border: $purple;
                border-radius: 0;
                height: 5.2rem;
                //@include averta-std-regular(1.6rem, 1.6rem);
            }

            .choices__list--single {
                padding-top: 8px;
            }

            .choices[data-type*='select-one']:after {
                border-color: transparent;
                width: 8px;
                height: 4px;
                background: transparent url('../images/common/arrow-small-down.png') no-repeat center center;
                background-size: 8px 4px;
                margin-top: -4px;
            }

            .choices__list--dropdown {
                background-color: $purple;
                border: $purple;
            }

            .choices__list--dropdown .choices__item--selectable.is-highlighted {
                background-color: darken($purple, 2%);
            }
        }
    }

    .section-content {
        margin-top: 5rem;
        z-index: 1;

        .r-2 {
            margin-top: 4rem;
            text-align: center;
        }

        .container-loader {
            position: absolute;
            height: 5.4rem;
            //opacity: 0;
            /*display: flex;*/
/*            align-items: center;
            justify-content: center;

            img {
                width: 4rem;
                height: 4rem;
            }*/
        }

        .r-1 {
            position: relative;
            overflow: hidden;
            height: 0;
        }

        .r-2 {
            .column-12:last-child {
                @include autoAlpha(0);
            }
        }

        .main-news-container {

        }

        .news-container {
            margin-left: -5rem;
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        .column-4 {
            flex: 0 0 auto;
            float: none;
            width: calc((100% - 15rem) / 3);
            margin-left: 5rem;
            margin-bottom: 5rem;

            &.large {
                width: calc(100% - 5rem);
            }
        }
    }

    @media screen and (max-width: $mobile) {

        .page__inner {
            padding: 16rem 0 6rem 0;
        }

        .section-title {
            margin-top: 9rem;
        }

        .section-content {

            .r-2 {
                margin-top: 2rem;
            }

            .news-container {
                margin-left: 0;
            }

            .column-4 {
                width: 100% !important;
                margin-left: 0 !important;
            }
        }
    }
}