#error {
    height: calc(100vh - 20rem);

    .background {
        @include center-y;
        right: 0;
        z-index: 1;
        svg {
            width: 73rem;
            height: 25rem;
        }
    }

    .section-content,
    .grid,
    .row {
        height: 100%;
    }

    .r-1 {
        z-index: 2;
        display: flex;
        align-items: center;

        .title {
            @extend h1;
        }

        .text-content {
            margin-top: 4rem;

        }

        .button {
            margin-top: 4rem;
        }
    }

    @media screen and (max-width: $mobile) {
        .background {
            display: none;
        }
    }
}