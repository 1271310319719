.block.block-news  {
    padding: 10rem 0;

    .up-title {
        @include averta-std-regular(1.4rem, 1.4rem, 0.03em);
        text-transform: uppercase;
    }

    h2 {
        margin-top: 1rem;
    }

    .r-1 {
        text-align: center;
    }

    .r-2 {
        margin-top: 6rem;

        .swiper {
            overflow: visible;
        }

        .swiper-slide {
            @include set-grid-prop(width, 3, 4);
        }
    }

    .r-3 {
        margin-top: 8rem;

        .column-12 {
            text-align: right;
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 5rem 0;

        .up-title {
            font-size: 1.2rem;
            line-height: 1.2rem;
        }

        .r-2 {

            .swiper-slide {
                //width: 30rem !important;
            }
        }

        .r-3 {
            margin-top: 5rem;

            .column-12 {
                text-align: left;
            }
        }
    }

}