.block.block-text-logo {
    padding: 10rem 0;

    .row {
        display: flex;
        align-items: center;

        [class^='column'] {
            float: none;
        }
    }

    .title {
        @include averta-std-regular(2.4rem, 3.4rem, 0.05em);
        color: white;
    }

    .text-content {
        margin-top: 4rem;
    }

    .button-line {
        margin-top: 4rem;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;

        .shape {
            opacity: 0.85;
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: 1;
            height: 100%;
            max-height: 35rem;
            width: 80%;
            background: rgb(144,47,221);
            background: linear-gradient(25deg, rgba(144,47,221,1) 0%, rgba(0,141,255,1) 100%);
            transform: translate(-50%, -50%) skewX(344deg);
        }

        img {
            position: relative;
            z-index: 2;
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
        }
    }

    &.type-1 {

        .logo {
            @include set-grid-prop(margin-left, 2, 1);
        }

        .content {
            @include set-grid-prop(margin-left, 1, 1);
        }
    }

    &.type-2 {

        .logo {
            order: 1;
        }

        .content {
            order: 2;
            @include set-grid-prop(margin-left, 3, 2);
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 5rem 0;


        .row {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .logo {
            order: 1 !important;
            height: 20rem;

            .shape {
                height: 20rem;
                width: 90%;
            }

            img {
                max-width: 80%;
                max-height: 80%;
            }
        }

        .content {
            order: 2 !important;
            margin-top: 5rem;
        }

        .title {
            font-size: 1.8rem;
            line-height: 2.8rem;
        }
    }

    + .block-text-logo {
        padding-top: 5rem;
    }

}