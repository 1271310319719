.block.block-image {
    padding: 10rem 0;

    .r-0 {
        margin-bottom: 4rem;

        .column-10 {
            @include set-grid-prop(margin-left, 1, 1);
            text-align: left;
        }
    }

    .r-1 {
        position: relative;

        .column-12 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 100%;
                height: auto;
            }

            /*&:after {
                opacity: 0.4;
                content: "";
                @include full-absolute;
                background-color: $blue-2;
                z-index: 1;
            }*/
        }
    }

    .column-10 {
        @include set-grid-prop(margin-left, 1, 1);
        margin-top: 4rem;
        text-align: center;
    }

    @media screen and (max-width: $mobile) {
        padding: 5rem 0;

        .column-10 {
            margin-top: 2rem;
        }
    }
}