.block.block-head-banner {
    height: 66rem;

    .block-head-banner__inner {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    &:after {
        content: "";
        background-color: $blue-1;
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: -1px;
        left: 0;
        z-index: 10;
    }

    &.large {
        height: 77rem;

        .r-1 {
            align-items: center;
            padding-bottom: 0;
        }
    }

    .block-head-banner__background {
        @include full-absolute;
        z-index: 1;

        .video {
            @include full-absolute;
            z-index: 4;

            video {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        &:after {
            content: "" ;
            @include full-absolute;
            height: 100%;
            background: rgb(13,17,37);
            background: linear-gradient(90deg, rgba(13,17,37,1) 0%, rgba(13,17,37,0) 100%);
            //opacity: 0.3;
            z-index: 5;
        }

        .layer {
            @include full-absolute;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            &.layer-1 {
                img {
                    width: 110%;
                    height: 110%;
                    @include center-y;
                }
            }

            &.layer-2 {
                z-index: 2;
            }

            &.layer-3 {
                z-index: 3;
            }
        }
    }

    .grid,
    .row {
        z-index: 10;
        height: 100%;
    }

    .r-1 {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        padding-bottom: 10rem;
        z-index: 10;
        transform: translate3d(0, 0, 0.1px);
    }

    h1 {
        @include averta-std-bold();
        font-size: 5.6rem;
        line-height: 1.2;
        color: white;

        u {
            position: relative;
            display: inline-block;
            @include averta-std-light();
            text-decoration: none;
            //box-shadow:inset 0 -2px 0 rgba(0,118,228,0.5), 0 2px 0 rgba(0,118,228,0.5);

            &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                content: "";
                width: 105%;
                height: 4rem;
                background: rgb(144,47,221);
                background: linear-gradient(90deg, rgba(144,47,221,1) 0%, rgba(0,141,255,1) 100%);
                transform: translate(-50%, 0) skewX(-25deg);
                z-index: -1;
            }
        }
    }

    .subtitle  {
        margin-top: 4rem;
        color: white;
        max-width: 48rem;
    }

    @media screen and (max-width: $mobile) {
        height: calc(100vh - 80px);

        &.large {
            height: calc(100vh - 80px);

            .r-1 {
                padding-bottom: 30vh;
                align-items: flex-end;
            }
        }

        .row {
            padding-top: 0;
        }

        .block-head-banner__background {

            &:after {
                background: linear-gradient(-90deg, rgba(13,17,37,0) 0%, rgba(13,17,37,1) 100%);
            }
        }

        .r-1 {
            align-items: flex-end;
            padding-bottom: 9rem;
        }

        h1 {
            font-size: 3rem;

            u {

                &:after {
                    height: 2rem;
                }
            }
        }

        .subtitle {
            margin-top: 3rem;
        }
    }
}