#home {

    .block-solutions {
        margin-top: 10rem;
    }

    .block-testimonial {
        padding: 0;
    }
}

@media screen and (max-width: $mobile) {
    #home {}
}
