.block.block-list {
    padding: 10rem 0;
    background-color: $blue-1;

    .r-2 {
        margin-top: 4rem;

        &:first-child {
            margin-top: 0;
        }
    }

    .column-10 {
        @include set-grid-prop(margin-left, 1, 1);
    }

    .list-item {
        padding: 5rem 0;
        border-top: 1px solid rgba(151, 151, 151, 0.26);
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:first-child {
            border-top: none;
        }

        .list-item__icon {
            flex: 0 0 auto;
            width: 7rem;
            margin-right: 8rem;

            img {
                max-width: 100%;
                height: auto;
            }
        }

        .list-item__content {

            .list-item__content-title {
                color: white;
                @include averta-std-bold();
                font-size: 2.4rem;
                line-height: 1.25;
            }

            p {
                margin-top: 2rem;
            }
        }

        .button-rect {
            padding-left: 13rem;
            margin-left: auto;
            flex: 0 0 auto;
        }

    }

    @media screen and (max-width: $mobile) {
        padding: 5rem 0;

        .list-item {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .list-item__content {
                margin: 4rem 0 0 0;

                &:first-child {
                    margin-top: 0;
                }

                .list-item__content-title {
                    font-size: 1.8rem;
                }
            }

            .button-rect {
                margin-left: 0;
                margin-top: 4rem;
                padding-left: 0;
            }
        }
    }
}