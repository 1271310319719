.block.block-text-image {
    padding: 10rem 0;

    .steps {
        left: -8rem;
        top: 0;
        height: 59rem;
        position: absolute;
        @include averta-std-bold(1.6rem, 2.1rem, 0.034em);
        display: flex;
        align-items: center;
        z-index: 10;

        .steps__inner {
            position: relative;
            /*transform-origin: 0 0;
            transform: translate(-50%, 0) rotate(90deg);*/
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .line {
            width: 1px;
            height: 9rem;
            background-color: white;
            opacity: 0.1;
            margin: 1.4rem 0;
        }

        .current {
            color: white;
        }

        span {
            display: block;
            //transform-origin: 0 0;
            transform: rotate(90deg);
        }
    }

    .item {
        display: flex;
        align-items: center;
        margin-top: 15rem;

        &:first-child {
            margin-top: 0;
        }

        [class^='column'] {
            float: none;
        }

        .content {
            //@include set-grid-prop(margin-left, 1, 1);

            .number {
                @include averta-std-bold(1.6rem, 2.2rem, 0.03em);
                color: $purple;
            }

            .title {
                margin-top: 2rem;
                @include averta-std-bold(2.4rem, 3.4rem, 0);
                color: white;
            }

            .text-content {
                margin-top: 4rem;
            }
        }

        .illustration {
            @include set-grid-prop(margin-left, 2, 2);

            &:before {
                display: none;
                content: "";
                opacity: 0.7;
                z-index: 1;
                width: 32rem;
                height: 130%;
                background: rgb(69,218,241);
                background: linear-gradient(90deg, rgba(69,218,241,1) 0%, rgba(19,200,182,1) 100%);
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(50%, -50%) skewX(-25deg);
            }

            img {
                position: relative;
                z-index: 2;
                width: 100%;
                height: auto;
            }
        }

        &:nth-child(even) {

            .content {
                order: 2;
                @include set-grid-prop(margin-left, 2, 2);
            }

            .illustration {
                order: 1;
                margin-left: 0;
            }
        }

        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(7),
        &:nth-child(10) {

            .illustration {
                &:before {
                    display: block;
                }
            }
        }

        &:nth-child(4),
        &:nth-child(10) {
            .illustration {
                &:before {
                    left: 0;
                    transform: translate(-50%, -50%) skewX(-25deg);
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 5rem 0;

        .item {
            flex-direction: column;
            margin-top: 10rem;

            .illustration {
                order: 1;

                &:before {
                    transform: translate(90%, -50%) skewX(-25deg);
                }
            }

            .content {
                margin-top: 5rem;
                order: 2;

                .number {
                    font-size: 1.4rem;
                    line-height: 1;
                }

                .title {
                    font-size: 1.8rem;
                    line-height: 2.8rem;
                }

                .text-content {
                    margin-top: 2rem;
                }
            }

            &:nth-child(4),
            &:nth-child(10) {
                .illustration {
                    &:before {
                        transform: translate(-70%, -50%) skewX(-25deg);
                    }
                }
            }
        }
    }
}