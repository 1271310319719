a {
    text-decoration: none;
}

strong {
    font-weight: normal;
    @include averta-std-bold();
}

h1 {
    @include averta-std-bold(5.6rem, 6.8rem, -0.01em);
    color: white;
}

h2 {
    @include averta-std-bold(3.2rem, 4.6rem, -0.05em);
    color: white;
}

.wp-block-table {
    margin-top: 3.8rem !important;

    &:first-child {
        margin-top: 0;
    }

    tbody {

        tr:first-child {
            background-color: $purple;
            color: white;

            td {
                border-left-color: rgba(255, 255, 255, 0.3);

                &:first-child {
                    border-left: none;
                }
            }
        }
    }
}

table {
    border-collapse: separate;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: $blue-2;
}

td {
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    padding: 1rem;
    font-size: 1.4rem;

    &:first-child {
        border-left: none;
    }
}


    /**
    TEXT CONTENT
     */
.text-content {

    > * {
        margin-top: 3.8rem;

        &:first-child {
            margin-top: 0;
        }
    }

    h1 {
    }

    h2 {
        @include averta-std-bold(3.2rem, 1.43);
        color: white;
    }

    h3 {
        @include averta-std-bold(2.2rem, 1.43);
        color: $purple;
    }

    h4 {
        @include averta-std-bold(2.4rem, 1.43, 0.05em);
        color: white;
    }

    ul,
    ol,
    p {

    }

    ul {
        padding-left: 3rem;

        li {
            position: relative;
            margin-top: 1rem;

            &:before {
                content: "";
                width: 0.6rem;
                height: 0.6rem;
                background-color: $purple;
                border-radius: 50%;
                position: absolute;
                left: -2.6rem;
                top: 1rem;
            }
        }
    }

    strong {
        @include averta-std-bold();
    }

    em {
        @include averta-std-regular-italic();
    }

    a {
        color: $purple;
        text-decoration: underline;
        transition: color 0.6s $easeOutQuart;

        html.desktop & {
            &:hover {
                color: lighten($purple, 10%)
            }
        }
    }
}

/**
NEW CONTENT
 */
.new-item {
    //@include set-grid-prop(margin-left, 1, 0);

    a {
        display: block;
        height: 100%;
        width: 100%;
    }

    .thumbnail {
        width: 100%;
        height: 25rem;
        overflow: hidden;

        &:after {
            content: "";
            opacity: 0.5;
            background-color: $blue-2;
            @include full-absolute;
            z-index: 2;
            transition: opacity 0.6s $easeOutQuart;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .metas {
        margin-top: 4rem;
        @include averta-std-regular(1.4rem, 1.4rem, 0.03em);
        text-transform: uppercase;
        display: flex;
        align-items: center;

        .category {
            ul {
                display: flex;
                align-content: center;

                li {
                    margin-left: 0.4rem;
                    position: relative;

                    &:before {
                        content: "-";
                        display: inline;
                        margin-right: 0.4rem;
                    }

                    &:first-child {
                        margin-left: 0;

                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }

        .date {
            margin-left: 2rem;
        }
    }

    .excerpt {
        margin-top: 2rem;
        @include averta-std-regular(2rem, 3rem, -0.036em);
        color: white;
    }

    html.desktop & {
        &:hover {
            .thumbnail {
                &:after {
                    opacity: 0;
                }
            }
        }
    }

    &.large {
        height: 48.6rem;

        .thumbnail {
            width: 100%;
            height: 100%;

            &:before {
                opacity: 0.5;
                content: "";
                @include full-absolute;
                pointer-events: none;
                background: rgb(15,20,42);
                background: linear-gradient(180deg, rgba(15,20,42,0) 0%, rgba(15,20,42,1) 100%);
                z-index: 1;
            }
        }

        .over {
            position: absolute;
            z-index: 2;
            bottom: 3rem;
            left: 3rem;
            max-width: 40rem;
        }

        + .new-item {
            margin-left: 0;
        }
    }

    &:first-child {
        margin-left: 0;
    }

}

/**
SPONSORED BLOCK
 */
.sponso-item {
    @include set-grid-prop(margin-left, 1, 0);
    height: 40rem;

    a {
        display: block;
        height: 100%;
        width: 100%;

        html.desktop & {

            &:hover {
                .button-rect {
                    .button__inner {
                        color: white;

                        &:before {
                            background-color: $green;
                            transform: skewX(-25deg);
                        }
                    }
                }
            }
        }
    }

    .sponsored-banner {
        opacity: 0.85;
        background-color: $green;
        position: absolute;
        left: 0;
        top: 0;
        height: 4rem;
        padding: 0 1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @include averta-std-regular(1.2rem, 1.2rem, 0.03em);
        color: $blue-2;
        text-transform: uppercase;
        z-index: 10;
    }

    .background {
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;

        &:after {
            content: "" ;
            background: rgb(13,17,37);
            background: linear-gradient(330deg, rgba(13,17,37,0) 0%, rgba(13,17,37,1) 100%);
            @include full-absolute;
            //opacity: 0.3;
            z-index: 1;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .over {
        padding: 5rem;
        @include full-absolute;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        z-index: 2;

        .title {
            @include averta-std-bold(2.4rem, 3.4rem, -0.05em);
            color: white;
        }

        .description {
            color: white;
            margin-top: 2rem;
            max-width: 46rem;
        }

        .button-rect {
            margin-top: 3rem;
        }
    }
}


@media screen and (max-width: $mobile) {

    h1 {
        font-size: 3.2rem;
        line-height: 4.3rem;
    }

    h2 {
        font-size: 2rem;
        line-height: 3rem;
    }

    .text-content {

        > * {
            margin-top: 2.6rem;
        }

        h2 {
            font-size: 2rem;
            line-height: 3rem;
        }

        h4 {
            font-size: 1.8rem;
            line-height: 2.8rem;
        }
    }

    .new-item.large,
    .new-item {
        height: auto !important;

        .thumbnail {
            height: 18.8rem !important;
        }

        .over {
            position: relative;
            bottom: auto;
            left: auto;
            max-width: 100%;
        }

        .metas {
            font-size: 1.2rem;
            line-height: 1.2rem;
        }

        .excerpt {
            font-size: 1.6rem;
            line-height: 2.6rem;
        }
    }

    .sponso-item {
        height: auto;

        .sponsored-banner {
            left: 0;
            right: auto;
            height: 2.4rem;
            font-size: 1.2rem;
            line-height: 1.2rem;
            padding-top: 0.2rem;
        }

        .background {
            position: absolute;
            top: 0;
            left: 0;
        }

        .over {
            position: relative;
            top: auto;
            left: auto;
            padding: 7rem 3rem 3rem 3rem;
            min-height: 34rem;

            .title {
                font-size: 2rem;
                line-height: 3rem;
            }

            .description {
                margin-top: 2rem;
            }
        }
    }
}