#single {

    .section-head {
        height: 66rem;

        .background {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: 1;

            &:after {
                content: "" ;
                background: rgb(13,17,37);
                background: linear-gradient(330deg, rgba(13,17,37,0) 0%, rgba(13,17,37,1) 100%);
                @include full-absolute;
                //opacity: 0.3;
                z-index: 5;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .grid {
            position: relative;
            height: 100%;
            z-index: 2;

            .row {
                height: 100%;
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                padding-bottom: 13rem;
            }
        }

        .metas {
            @include averta-std-regular(1.4rem, 1.4rem, 0.03em);
            text-transform: uppercase;
            display: flex;
            align-items: center;

            .category {
                ul {
                    display: flex;
                    align-content: center;

                    li {
                        margin-left: 0.4rem;
                        position: relative;

                        &:before {
                            content: "-";
                            display: inline;
                            margin-right: 0.4rem;
                        }

                        &:first-child {
                            margin-left: 0;

                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }

            .date {
                margin-left: 2rem;
            }
        }

        h1 {
            margin-top: 2rem;
        }
    }

    @media screen and (max-width: $mobile) {

        .section-head {
            height: 100vh;

            .grid {
                .row {
                    //padding-bottom: 8rem;
                }
            }
        }
    }

}