.block-quote-content {
    width: 100%;
    padding: 10rem 0;
    background-color: $blue-2;

    .c-1 {
        @include set-grid-prop(margin-left, 1, 1);
        @include averta-std-bold();
        font-size: 3.2rem;
        color: white;
        line-height: 1.43;

        &:before {
            opacity: 0.7;
            position: absolute;
            content: "";
            background: rgb(144,47,221);
            background: linear-gradient(25deg, rgba(144,47,221,1) 0%, rgba(0,141,255,1) 100%);
            width: 2.8rem;
            height: 4.4rem;
            z-index: 1;
            left: -1rem;
            top: 0.4rem;
            transform: skewX(-25deg);
        }

        p {
            z-index: 2;
        }
    }

    .c-2 {
        @include set-grid-prop(margin-left, 2, 1);
    }

    @media screen and (max-width: $mobile) {
        padding: 5rem 0;

        .c-1 {
            font-size: 2rem;
            line-height: 3rem;

            &:before {
                width: 2.8rem;
                height: 4.4rem;
            }
        }

        .c-2 {
            margin-top: 4rem;
        }
    }
}