.block.block-navigation {
    padding: 10rem 0;

    .r-1 {
        text-align: center;
    }

    .r-2 {
        margin-top: 6rem;

        .column-5 {
            @include set-grid-prop(margin-left, 1, 0);

            &:first-child {
                @include set-grid-prop(margin-left, 1, 1);
            }

            a {
                display: block;

                &:after {
                    content: "";
                    background-color: $blue-2;
                    @include full-absolute;
                    z-index: 2;
                    opacity: 0.5;
                    transition: opacity 0.6s $easeOutQuart;
                }
            }

            .thumbnail {
                overflow: hidden;
                height: 26rem;

                &:after {
                    opacity: 0.5;
                    content: "";
                    @include full-absolute;
                    pointer-events: none;
                    background: rgb(15,20,42);
                    background: linear-gradient(180deg, rgba(15,20,42,0) 0%, rgba(15,20,42,1) 100%);
                    z-index: 1;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .over {
                @include full-absolute;
                z-index: 2;
                display: flex;
                align-items: center;
                padding: 0 3rem;

                .title {
                    position: relative;
                    @include averta-std-bold(2.4rem, 3.4rem);
                    color: white;

                    &:before {
                        content: "";
                        opacity: 0;
                        position: absolute;
                        background: rgb(69,218,241);
                        background: linear-gradient(90deg, rgba(69,218,241,1) 0%, rgba(19,200,182,1) 100%);
                        width: 2.8rem;
                        height: 4.4rem;
                        z-index: -1;
                        left: -1rem;
                        top: -0.6rem;
                        transform: translate(0, 4px) skewX(-25deg);
                        transition: transform 0.6s $easeOutQuart, opacity 0.6s $easeOutQuart;
                    }
                }
            }

            html.desktop & {
                &:hover {
                    a {
                        &:after {
                            opacity: 0;
                        }

                        .over {

                            .title {

                                &:before {
                                    transform: translate(0, 0) skewX(-25deg);
                                    opacity: 0.7;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 5rem 0;

        .r-2 {
            margin-top: 5rem;

            .column-5 {
                margin-top: 3rem;

                a {
                    &:after {
                        opacity: 0;
                    }
                }

                .thumbnail {
                    height: 17rem;
                }

                .over {
                    .title {
                        font-size: 1.8rem;
                        line-height: 2.8rem;
                    }
                }
            }
        }
    }

}